import React, { useEffect, useState } from "react";
import "./Header.css";

export default function Header(){ 

    return (
        <div className="header">
            
        </div>
    );
}